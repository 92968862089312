<template>
  <div class="apply-sale" v-if="is_show">
    <div class="goods-content">
      <order-goods :goodsList="itemList"></order-goods>
    </div>
    <div class="good-number-wrapper">
      <div class="left">商品数量</div>
      <yd-spinner
        :max="goodMaxNumber"
        :longpress="false"
        min="1"
        v-model="goodNumber"
      ></yd-spinner>
    </div>

    <div class="sale-title" v-if="status == 4">补货理由</div>
    <div class="sale-title" v-else>换货理由</div>

    <div class="reason-contnet">
      <div
        class="reason-item"
        v-for="(item, index) in reasonList"
        :key="index"
        @click="chooseReason(item.id)"
      >
        <p>{{ item.content }}</p>
        <div class="icon">
          <img
            src="~@/assets/ic_xuanze_color@3x.png"
            v-if="choosedId == item.id"
          />
          <img src="~@/assets/ic_xuanze copy@3x.png" alt v-else />
        </div>
      </div>
    </div>
    <div class="sale-title">详情描述原因</div>
    <div class="sale-detail">
      <textarea
        name
        id
        placeholder="详情描述理由…"
        v-model="txt"
        :maxlength="maxLength"
      ></textarea>
      <div class="inp-num">{{ txt.length }}/{{ maxLength }}</div>
    </div>
    <div class="sale-title">描述照片 {{ imgList.length }}/3</div>
    <div class="img-box">
      <div class="img-item" v-for="(item, index) in imgList" :key="index">
        <img
          src="~@/assets/icon_close_white_14@3x.png"
          alt
          class="icon"
          @click="delImg(index)"
        />
        <img :src="item" alt class="up-img" />
      </div>
      <div class="img-item up-item">
        <!-- 上传图片按钮 -->
        <div class="upload-box" v-if="imgList.length < maxImgNum">
          <img class="upload-bg" src="~@/assets/icon-up-picture2@3x.png" alt />
          <input type="file" @change="doUpload($event)" accept="image/*" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-btn" @click="apply()">提交申请</div>
    </div>
  </div>
</template>
<script>
import orderGoods from "@/components/order/orderGoods";
import {
  orderDetail,
  getRefundReason,
  applySale
} from "@/services/orderApi.js";
import { mapState } from "vuex";
import { client } from "@/utils/alioss";
import { getStore } from "@/utils/common";
import md5 from "js-md5";
import { randomWord } from "@/utils/common";
import { customAccount } from "@/utils/custom";
export default {
  name: "apply-sale",
  data() {
    return {
      txt: "",
      maxLength: 100,
      status: 0,
      order_id: this.$route.query.order_id,
      reasonList: [], // 退货原因
      itemList: [], // 商品列表
      choosedId: "",
      imgList: [],
      img_url: "",
      maxImgNum: 3,
      goodMaxNumber: 1,
      goodNumber: 1,
      is_show: false
    };
  },
  computed: {
    ...mapState({
      type: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      integralRate: state => state.config.integral_rate // 积分比例
    })
  },
  components: {
    orderGoods
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 判断是否有token
      if (getStore('session_id') === null && customAccount('VUE_APP_JY')) {
        vm.$dialog.loading.open('正在跳转登录...');
        setTimeout(() => {
            vm.$dialog.loading.close();
            vm.$router.replace({
              path:'login'
            })
        }, 1500);
        return false
      } else {
        vm.is_show = true
      }
    })
  },
  methods: {
    chooseReason(id) {
      this.choosedId = id;
    },
    // 删除图片
    delImg(index) {
      this.imgList.splice(index, 1);
    },
    // 提交申请
    async apply() {
      if (!this.choosedId) {
        if (this.status == 4) {
          this.$dialog.toast({
            mes: "请选择补货原因"
          });
        } else {
          if (this.type == 4) {
            this.$dialog.toast({
              mes: "请选择换货原因"
            });
          } else {
            this.$dialog.toast({
              mes: "请选择退款原因"
            });
          }
        }
        return;
      }
      if (!this.txt) {
        this.$dialog.toast({
          mes: "请填写详细原因"
        });
        return;
      }
      if (this.imgList.length == 0) {
        this.$dialog.toast({
          mes: "请上传描述图片"
        });
        return;
      }
      try {
        this.$dialog.loading.open("提交中...");
				
				const startRandom = randomWord(false, 4);
				const endRandom = randomWord(false, 4);
				const sign = `${startRandom}order_id:${this.order_id}${endRandom}`;
				
        let params = {
          operate_type: this.status,
          order_id: this.order_id,
          reason_id: this.choosedId,
          reason: this.txt,
          proof: this.imgList.join(","),
          item_nums: this.goodNumber,
					sign: md5(sign),
					random: `${startRandom}${endRandom}`,
					mobile: true
        };
        let data = await applySale(params);
        this.$dialog.loading.close();
        if (data.code === 0) {
          this.$dialog.toast({
            mes: "提交成功",
            timeout: 1500,
            callback: () => {
              this.$router.replace({
                path: "/apply-success",
                query: {
                  order_id: this.order_id
                }
              });
            }
          });
        } else {
          this.$dialog.toast({
            mes: data.msg,
            timeout: 1500
          });
        }
      } catch (err) {
        throw err;
      }
    },
    async doUpload(event) {
      const _this = this;
      const file = event.target.files[0];
      if (file.type.split('/')[0] !== 'image') {
          this.$dialog.toast({
            mes: '请上传图片',
            icon: 'failure',
            timeout: 1500
          });
          return false
      }
      this.$dialog.loading.open("上传中...");
      if (file) {
        let sign = _this.signature();
        let name = `Uploads/image/${sign}/${new Date().getTime()}${file.name}`;
        // 上传
        try {
          let clientObj = await client();
          let result = await clientObj.put(name, file);
          _this.img_url = result.url;
          this.$dialog.loading.close();
          this.$dialog.toast({
            mes: "成功",
            icon: "success",
            timeout: 1500
          });
        } catch (e) {
          console.log(e);
        }
      }
      // const _this = this;
      // const max_file_num = this.maxImgNum; //  文件数量上限
      // const files = event.target.files;
      // const fileLen = event.target.files.length;
      // if (fileLen > max_file_num - _this.imgList.length) {
      //   this.$dialog.toast({
      //     mes: `只能选择${max_file_num - _this.imgList.length}张图片`
      //   });
      //   return;
      // }
      // _this.$dialog.loading.open("上传中...");
      // if (fileLen && this.imgList.length <= this.maxImgNum) {
      //   for (let i = 0; i < fileLen; i++) {
      //     const file = files[i];
      //     let sign = _this.signature();
      //     let name = `Uploads/image/${sign}/${new Date().getTime()}${
      //       file.name
      //     }`;
      //     // 上传
      //     try {
      //       let clientObj = await client();
      //       let result = await clientObj.multipartUpload(name, file);
      //       _this.img_url = result.res.requestUrls[0];
      //       if(_this.img_url.indexOf('?')>-1){
      //         let img_url = _this.img_url.split("?");
      //         _this.img_url = img_url[0];
      //         console.log(_this.img_url);
      //       }

      //       this.$dialog.loading.close();
      //       this.$dialog.toast({
      //         mes: "成功",
      //         icon: "success",
      //         timeout: 1500
      //       });
      //     } catch (e) {
      //       throw e;
      //     }
      //   }
      // }
    },
    async getOrderDetail() {
      try {
        let data = await orderDetail({
					order_id: this.order_id
				});
        if (data.code === 0) {
          this.itemList = data.data.item_list;
          this.goodNumber = this.itemList[0].number;
          this.goodMaxNumber = this.itemList[0].number;
        }
      } catch (err) {
        throw err;
      }
    },
    async getReasonList() {
      try {
        let data = await getRefundReason(this.status);
        if (data.code === 0) {
          this.reasonList = data.data;
        }
      } catch (err) {
        throw err;
      }
    },
    signature() {
      let date = new Date();
      let y = date.getFullYear();
      let m = this.appendZero(date.getMonth() + 1);
      return `${y}-${m}`;
    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    }
  },
  created() {
    this.getOrderDetail();
    // history.go(0)
  },
  mounted() {
    this.status = this.$route.query.type;
    this.getReasonList();
  },
  watch: {
    img_url(val) {
      if (val) {
        this.imgList.push(val);
      }
    }
  }
};
</script>
<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.apply-sale {
  padding-bottom: 1.5rem;
  box-sizing: border-box;
  overflow-y:scroll .goods-content {
    padding: 0.3rem 0.3rem 0.2rem;
    box-sizing: border-box;
  }
  .good-number-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    font-size: 0.28rem;
    color: #666666;
    height: 0.8rem;
    .left {
      margin-right: 0.5rem;
    }
  }

  .sale-title {
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    font-size: 0.28rem;
    color: #666666;
    height: 0.8rem;
  }

  .reason-contnet {
    background: #fff;
    padding-left: 0.3rem;
    box-sizing: border-box;

    .reason-item {
      height: 0.88rem;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.3rem;
      box-sizing: border-box;
      font-size: 0.32rem;
      color: #333333;

      .icon {
        width: 0.28rem;
        height: 0.28rem;

        img {
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }
  }

  .sale-detail {
    background: #fff;
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    height: 2rem;

    textarea {
      width: 100%;
      height: 1.34rem;
      border: none;
    }

    .inp-num {
      font-size: 0.26rem;
      color: #cccccc;
      text-align: right;
    }
  }

  .img-box {
    display: flex;
    align-items: center;

    .img-item {
      width: 2.2rem;
      height: 2.2rem;
      margin-left: 0.2rem;
      background: #fff;
      position: relative;
      .up-img {
        width: 2.2rem;
        height: 2.2rem;
      }
      .icon {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 99;
      }
    }
    .upload-box {
      position: relative;
      .wh(2.2rem, 2.2rem);

      input {
        position: absolute;
        .wh(2.2rem, 2.2rem);
        right: 0;
        top: 0;
        opacity: 0;
      }

      .upload-bg {
        .wh(2.2rem, 2.2rem);
      }
    }

    .up-item {
      box-sizing: border-box;
      position: relative;
    }
  }

  .bottom {
    width: 100%;
    background: #fff;
    height: 1.2rem;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e1e1e1;

    .bottom-btn {
      width: 6.9rem;
      height: 0.88rem;
      border-radius: 0.88rem;
      background: var(--main-color);
      text-align: center;
      line-height: 0.88rem;
      font-size: 0.32rem;
      color: #fff;
    }
  }
}
</style>
