var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_show
    ? _c("div", { staticClass: "apply-sale" }, [
        _c(
          "div",
          { staticClass: "goods-content" },
          [_c("order-goods", { attrs: { goodsList: _vm.itemList } })],
          1
        ),
        _c(
          "div",
          { staticClass: "good-number-wrapper" },
          [
            _c("div", { staticClass: "left" }, [_vm._v("商品数量")]),
            _c("yd-spinner", {
              attrs: { max: _vm.goodMaxNumber, longpress: false, min: "1" },
              model: {
                value: _vm.goodNumber,
                callback: function ($$v) {
                  _vm.goodNumber = $$v
                },
                expression: "goodNumber",
              },
            }),
          ],
          1
        ),
        _vm.status == 4
          ? _c("div", { staticClass: "sale-title" }, [_vm._v("补货理由")])
          : _c("div", { staticClass: "sale-title" }, [_vm._v("换货理由")]),
        _c(
          "div",
          { staticClass: "reason-contnet" },
          _vm._l(_vm.reasonList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "reason-item",
                on: {
                  click: function ($event) {
                    return _vm.chooseReason(item.id)
                  },
                },
              },
              [
                _c("p", [_vm._v(_vm._s(item.content))]),
                _c("div", { staticClass: "icon" }, [
                  _vm.choosedId == item.id
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/ic_xuanze_color@3x.png"),
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/ic_xuanze copy@3x.png"),
                          alt: "",
                        },
                      }),
                ]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "sale-title" }, [_vm._v("详情描述原因")]),
        _c("div", { staticClass: "sale-detail" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.txt,
                expression: "txt",
              },
            ],
            attrs: {
              name: "",
              id: "",
              placeholder: "详情描述理由…",
              maxlength: _vm.maxLength,
            },
            domProps: { value: _vm.txt },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.txt = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "inp-num" }, [
            _vm._v(_vm._s(_vm.txt.length) + "/" + _vm._s(_vm.maxLength)),
          ]),
        ]),
        _c("div", { staticClass: "sale-title" }, [
          _vm._v("描述照片 " + _vm._s(_vm.imgList.length) + "/3"),
        ]),
        _c(
          "div",
          { staticClass: "img-box" },
          [
            _vm._l(_vm.imgList, function (item, index) {
              return _c("div", { key: index, staticClass: "img-item" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("@/assets/icon_close_white_14@3x.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.delImg(index)
                    },
                  },
                }),
                _c("img", {
                  staticClass: "up-img",
                  attrs: { src: item, alt: "" },
                }),
              ])
            }),
            _c("div", { staticClass: "img-item up-item" }, [
              _vm.imgList.length < _vm.maxImgNum
                ? _c("div", { staticClass: "upload-box" }, [
                    _c("img", {
                      staticClass: "upload-bg",
                      attrs: {
                        src: require("@/assets/icon-up-picture2@3x.png"),
                        alt: "",
                      },
                    }),
                    _c("input", {
                      attrs: { type: "file", accept: "image/*" },
                      on: {
                        change: function ($event) {
                          return _vm.doUpload($event)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "bottom" }, [
          _c(
            "div",
            {
              staticClass: "bottom-btn",
              on: {
                click: function ($event) {
                  return _vm.apply()
                },
              },
            },
            [_vm._v("提交申请")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }